import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Actions, Context} from '../Contexts/AppContext';

export const useFetchNewsList = () => {
  const app = useContext(Context);
  const DEFAULT_LIMIT = 6;
  const {activeBrand} = app;
  const [newsList, setNewsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const fetchNewsList = useCallback(
    async ({prevNewsList, offset}) => {
      Actions.setLoading(true);
      try {
        const resp = await Actions.fetchNewsList({
          brand: activeBrand,
          limit: DEFAULT_LIMIT,
          offset: offset,
        });
        const {total_count, informations} = resp;
        setNewsList([...prevNewsList, ...informations]);
        setTotalCount(total_count);
        setOffset(offset);
      } catch (ex) {
        console.warn('fetchNewsList error: ', ex);
      }
      Actions.setLoading(false);
    },
    [activeBrand],
  );

  useEffect(() => {
    fetchNewsList({prevNewsList: [], offset: 0});
  }, [fetchNewsList]);

  const fetchNextPageNewsList = useCallback(
    async (_newsList, _offset) => {
      await fetchNewsList({
        prevNewsList: _newsList,
        offset: _offset + DEFAULT_LIMIT,
      });
    },
    [fetchNewsList],
  );

  return {
    fetchNextPageNewsList,
    newsList,
    offset,
    totalCount,
  };
};

export const useFetchNews = id => {
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState(null);

  useEffect(() => {
    async function fetchNews() {
      try {
        Actions.setLoading(true);
        setIsLoading(true);
        setNews(await Actions.fetchNews(id));
      } catch (ex) {
        console.warn('fetchNews error', ex);
      } finally {
        Actions.setLoading(false);
        setIsLoading(false);
      }
    }

    fetchNews();
  }, [id]);

  return {
    news,
    isLoading,
  };
};
