import React, {Fragment, useContext} from 'react';
import styled from 'styled-components';
import {useDimension} from '../../Hooks/AppHooks';
import {Context, NavActions} from '../../Contexts/AppContext';
import NewsItem from './NewsItem';
import {ChevronLeft} from '../../Components/SvgIcon';
import RectButton from '../../Components/RectButton';
import {
  Color,
  CustomNavBar,
  FlexCenter,
  FlexRow,
} from '../../Components/Widget';
import {useFetchNewsList} from '../../Hooks/NewsHooks';
import * as SvgIcon from '../../Components/SvgIcon';
import * as L from '../../Utils/Lang';
import {brandsDisplayArr} from '../../Domain/Brand';

function ListPage() {
  const {dimension} = useDimension();
  const app = useContext(Context);
  const {
    fetchNextPageNewsList,
    newsList,
    offset,
    totalCount,
  } = useFetchNewsList();

  if (!dimension?.innerWidth || !newsList) {
    return null;
  }

  const mobile = dimension.innerWidth <= app.breakpoints.mobile;
  const tablet = dimension.innerWidth <= app.breakpoints.tablet;

  return (
    <Fragment>
      {tablet && (
        <CustomNavBar>
          <ChevronLeft
            onClick={() => {
              NavActions.navigate('/home');
            }}
          />
          <h3>最新消息</h3>
          <ChevronLeft style={{visibility: 'hidden'}} />
        </CustomNavBar>
      )}
      <Wrapper mobile={mobile} tablet={tablet}>
        {!tablet && (
          <>
            <h2 style={{marginBottom: 32}}>最新消息</h2>
            <FlexRow
              style={{
                fontSize: 14,
                fontWeight: 300,
                color: Color.mainDark_70,
                marginBottom: 12,
              }}>
              <span
                style={{cursor: 'pointer'}}
                onClick={() => {
                  NavActions.navigate('/home');
                }}>
                {brandsDisplayArr.find(b => b.key === app.activeBrand).display}
              </span>
              <SvgIcon.ChevronRight size={16} color={Color.mainDark_70} />{' '}
              <span
                onClick={() => {
                  NavActions.navigate('/news');
                }}
                style={{cursor: 'pointer'}}>
                最新消息
              </span>
            </FlexRow>
            <div className="line" style={{marginBottom: 40}} />
          </>
        )}

        {newsList.map((item, itemIdx) => {
          return (
            <NewsItem
              item={item}
              key={itemIdx}
              mobile={mobile}
              onClick={() => NavActions.navigate(`/news/detail?id=${item.id}`)}
            />
          );
        })}
        {newsList.length < totalCount && (
          <FlexCenter>
            <RectButton
              onClick={() => {
                fetchNextPageNewsList(newsList, offset);
              }}
              text="Read More"
            />
          </FlexCenter>
        )}
      </Wrapper>
    </Fragment>
  );
}

const Wrapper = styled.div`
  padding: ${props => (props.tablet ? '64px 20px 0 20px' : '40px 50px')};
  flex: 1;

  & > .navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 20;

    & > h3 {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #141414;
    }
  }

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.27;
    margin: 0;
    padding: 0;
  }

  & > .line {
    height: 1px;
    background-color: #d8d8d8;
  }
`;

export default ListPage;
