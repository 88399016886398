import React from 'react';
import styled from 'styled-components';
import * as L from '../../Utils/Lang';
import {Color} from '../../Components/Widget';
import {useDimension} from '../../Hooks/AppHooks';
import {Breakpoints} from '../../Contexts/AppContext';
import SummaryFromHtmlString from '../../Utils/SummaryFromHtmlString';

function NewsItem(props) {
  const {item, onClick, style = {}, inDetailPage = false} = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;

  return (
    <ItemWrapper
      style={style}
      smallDevice={smallDevice}
      inDetailPage={inDetailPage}>
      <div className="image-wrapper" onClick={onClick}>
        <img src={item.image} alt="news item" />
      </div>

      <div className="detail">
        <div className="title" onClick={onClick}>
          {L.d(item.title)}
        </div>

        {!inDetailPage && <p>{SummaryFromHtmlString(L.d(item.description))}</p>}

        {smallDevice || inDetailPage ? (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className="date">{item.start_date}</div>
            {!inDetailPage && <div style={{flex: 1}} />}
            {!inDetailPage && (
              <div onClick={onClick} className="more">
                Read More <div className="see-more-arrow" />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="date">{item.start_date}</div>
            <div style={{flex: 1}} />
            <div onClick={onClick} className="more">
              Read More <div className="see-more-arrow" />
            </div>
          </>
        )}
      </div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.smallDevice || props.inDetailPage ? 'column' : 'row'};
  margin-bottom: ${props =>
    props.smallDevice || props.inDetailPage ? '50px' : '70px'};

  & .date {
    font-size: 14px;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }

  & > .image-wrapper {
    &:hover {
      filter: opacity(0.85);
    }
    width: 50%;
    padding-top: ${props =>
      props.smallDevice || props.inDetailPage ? '67%' : '33%'};
    background-color: #ccc;
    position: relative;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      cursor:pointer;
    }
  }

  & > .detail {
    width: 50%;
    padding: ${props =>
      props.smallDevice || props.inDetailPage
        ? '20px 0'
        : '20px 0px 20px 40px'};
    display: flex;
    flex-direction: column;

    & > .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 8px;
      cursor: pointer;
      ${props =>
        props.inDetailPage
          ? `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
                  `
          : ``}
      &:hover {
        color: ${Color.mainDark_70};
      }
    }

    & > p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      height: 38px;
      color: rgba(20, 20, 20, 0.7);
      margin-bottom: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-wrap: break-word;
    }

    .more {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.57;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        & .see-more-arrow {
          width: 48px;
        }
      }
    }
  }

  & .see-more-arrow {
    position: relative;
    width: 24px;
    background-color: ${Color.mainDark_70};
    height: 1px;
    margin-left: 12px;
    transition: width 0.1s ease-in;
    &:before {
      content: '';
      width: 8px;
      display: block;
      background-color: ${Color.mainDark_70};
      height: 1px;
      transform: rotate(30deg);
      transform-origin: right;
      position: absolute;
      right: 1px;
    }
  }
  @media screen and (max-width: ${Breakpoints.tablet}px) {

    & > .image-wrapper {
      width: 100%;
    }
    & > .detail {
      width: 100%;

      & > .title {
        font-size: 20px;
        line-height: 28px;
      }

      & > p {
      }

      .more {
        &:hover {
          & .see-more-arrow {
          }
        }
      }
    }

    & .see-more-arrow {
      }
    }
  }
`;

export default NewsItem;
